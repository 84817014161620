
<template>
  <div>
    <!-- <r-card>
      <template #header> -->
    <v-row no-gutters align="center">
      <v-col cols="2" sm="4">
        <h2>{{ $t('PageTitles.Company') }}</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          outlined
          hide-details
          dense
          v-model="search"
          :label="$t('message.Search')"
          :class="$vuetify.breakpoint.mobile ? 'mt-4' : 'pa-4'"
          background-color="searchField"
        />
      </v-col>
      <v-col cols="12" md="4" align="right" v-if="isManager">
        <v-btn
          :block="$vuetify.breakpoint.mobile"
          :class="$vuetify.breakpoint.mobile ? 'my-4' : ''"
          :color="$vuetify.theme.dark ? 'black' : 'white'"
          depressed
          large
          @click="addCompany"
        >
          <v-icon left>mdi-domain</v-icon>
          {{ $t('ButtonsLabel.AddCompany') }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- </template> -->
    <v-data-table :loading="loading" :items="Companies" :search="search" :headers="getHeaders" :items-per-page="5" sort-desc @click:row="showCompanyDetails">
    </v-data-table>
    <create-edit-company ref="CreateEditCompanyRef" @company-updated="bubbleEmit" />
    <!-- </r-card> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
import CreateEditCompany from '@/components/Dialogs/CreateEditCompany.vue';
export default {
  components: { CreateEditCompany },
  data: () => ({
    loading: false,
    search: null,
    Companies: [],
  }),
  computed: {
    ...mapGetters(['isManager']),
    getHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('CompaniesListHeaders.Name'), value: 'name' },
        { text: t('CompaniesListHeaders.Cvr'), value: 'cvr' },
        { text: t('CompaniesListHeaders.PhoneNumber'), value: 'phone' },
        { text: t('CompaniesListHeaders.AmountOfUsers'), value: 'usersCount' },
      ];
    },
  },
  methods: {
    async getCompanies() {
      this.loading = true;
      this.Companies = await apiService.getCompanies();
      this.loading = false;
    },
    showCompanyDetails(company) {
      this.$router.push({ name: 'CompanyDetails', params: { Id: company.id } });
    },
    addCompany() {
      this.$refs.CreateEditCompanyRef.createCompany();
    },
    bubbleEmit() {
      this.getCompanies();
    },
  },
  created() {
    this.getCompanies();
  },
};
</script>
