<template>
  <v-overlay :value="show" class="justify-end">
    <v-slide-x-reverse-transition>
      <v-card tile v-if="currentCompany" :light="!darkMode" :dark="darkMode" style="height: 100vh; width: 40vw">
        <v-card-title class="pl-4">
          <v-row no-gutters>
            <h3>{{ isEditing ? $t('DialogsTitles.EditCompany') : $t('DialogsTitles.AddNewCompany') }}</h3>
            <v-btn class="ml-auto" icon @click="close">
              <v-icon dark>mdi-close-thick</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-6">
          <v-form v-model="isValid" ref="editCompanyRef">
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('CompaniesListHeaders.Name') }}:</v-col>
              <v-col>
                <v-text-field v-model="currentCompany.name" hide-details="auto" outlined :rules="[rules.required]" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('CompaniesListHeaders.Cvr') }}:</v-col>
              <v-col>
                <v-text-field v-model="currentCompany.cvr" outlined hide-details="auto" :rules="[rules.required]" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('CompaniesListHeaders.PhoneNumber') }}:</v-col>
              <v-col>
                <v-text-field v-model="currentCompany.phone" outlined hide-details="auto" :rules="[rules.required]" dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('CompaniesListHeaders.Country') }}:</v-col>
              <v-col>
                <v-text-field v-model="currentCompany.country" hide-details="auto" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4" no-gutters>
              <v-col cols="12" md="3" align-self="center">{{ $t('CompaniesListHeaders.Address') }}:</v-col>
              <v-col>
                <v-text-field v-model="currentCompany.address" hide-details="auto" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="pl-4" no-gutters>
            <v-btn :block="$vuetify.breakpoint.mobile" @click="show = false" elevation="2">{{ $t('ButtonsLabel.Cancel') }}</v-btn>
            <v-btn
              :block="$vuetify.breakpoint.mobile"
              @click="saveCurrentCompany"
              color="success"
              class="ml-4"
              elevation="2"
              :loading="isSaving"
              :disabled="!isValid"
            >
              <v-icon left>mdi-check</v-icon>
              {{ isEditing ? $t('DialogsTitles.EditUser') : $t('DialogsTitles.AddNewUser') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-slide-x-reverse-transition>
  </v-overlay>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateEditCompany',
  data: () => ({
    show: false,
    isSaving: false,
    isValid: false,
    currentCompany: {},
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
    },
  }),
  computed: {
    ...mapGetters(['isAdmin', 'user', 'darkMode']),
    isEditing() {
      return this.currentCompany?.id ? true : false;
    },
  },
  methods: {
    async saveCurrentCompany() {
      this.isSaving = true;
      await apiService
        .createOrEditCompany(this.currentCompany)
        .then(() => {
          this.show = false;
          this.$emit('company-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    editCompany(company) {
      this.currentCompany = _.cloneDeep(company);
      this.show = true;
    },
    createCompany() {
      this.currentCompany = {
        name: null,
        cvr: null,
        phone: null,
        country: null,
        address: null,
        id: null,
      };
      this.show = true;
    },
    close() {
      this.$emit('company-updated');
      this.show = false;
    },
  },
};
</script>

<style>
</style>